


let focus = false;

const boot = () => {
	
	console.log("************ Let’s Go! ************");
	
	if( document.hasFocus() ){
		
		focus = true;
		
	}
	
	
	window.addEventListener( "focus", ( event ) => {
		
		if( !focus ){
			
			focus = true;
			
		}
		
	});
	
	
	window.addEventListener( "blur", ( event ) => {
		
		if( focus ){
			
			focus = false;
			
		}
		
	});
	
	document.addEventListener("click", ( event ) => {
		
		document.body.classList.remove("kb-nav");
		
	});
	
	document.addEventListener("keydown", ( event ) => {
		
		document.body.classList.add("kb-nav");
		
	});
	
	document.addEventListener("keyup", ( event ) => {
		
		// Nothing here
		
	});
	
	window.addEventListener("resize", () => {
		
		JS_SPAWN.resize();
		
	});
	
	
	MAIN_MENU.init();
	
	JS_SPAWN.init();
	
	LOOP.start();
	
	window.addEventListener("scroll", function( event ){
		
		POSTERS.scroll();
		
		JS_SPAWN.scroll();
		
		MAIN_MENU.scroll();
		
	});
	
	POSTERS.init();
	
	const hi = document.getElementById("Heading_index");
	
	if( hi != null ){
		
		MI_VIDEOS.init();
		
	}
	
	
	document.documentElement.classList.add("scroll-smooth");
	
	
};

const MAIN_MENU = {
	
	dom_main_menu_open_checkbox : false, 
	
	dom_header : false, 
	
	scroll_pos : 0, 
	
	
	init : () => {
		
		MAIN_MENU.dom_main_menu_open_checkbox = document.getElementById("open-main-menu-check");
		MAIN_MENU.dom_header = document.querySelector("body>header");
		
		const mql = window.matchMedia("(max-width: 1300px)");
		
		mql.onchange = (e) => {
			
			if ( !e.matches ) {
				
				if( MAIN_MENU.dom_main_menu_open_checkbox.checked ){
					
					MAIN_MENU.dom_main_menu_open_checkbox.checked = false;
					
				}
				
			}
			
		};
		
		
		document.getElementById("header-main-nav").onclick = () => {
			
			// console.log("clic!", MAIN_MENU.dom_main_menu_open_checkbox);
			
			if( MAIN_MENU.dom_main_menu_open_checkbox.checked ){
				
				MAIN_MENU.dom_main_menu_open_checkbox.checked = false;
				
			}
			
		};
		
		MAIN_MENU.scroll();
		
	}, 
	
	
	scroll : function(){
		
		if( window.scrollY > this.scroll_pos ){
			
			this.dom_header.classList.add('hidden');
			
			MAIN_MENU.dom_main_menu_open_checkbox.checked = false;
			
		}else{
			
			this.dom_header.classList.remove('hidden');
		}
		
		
		if( window.scrollY == 0 ){
			
			this.dom_header.classList.add('at_top');
			
		}else{
			
			this.dom_header.classList.remove('at_top');
		}
		
		this.scroll_pos = window.scrollY;
		
	}, 
	
	
};


const JS_SPAWN = {
	
	list : [],
	
	lng : 0,
	
	spawn_limit : 0,
	
	SPAWN_LIMIT_INCREMENT : 200,
	
	body_rect_top : 0,
	
	init : () => {
		
		JS_SPAWN.resize();
		
		const SPAWNS = document.getElementsByClassName("js-spawn");
		
		for( let spawn of SPAWNS ){
			
			spawn.classList.add("spawn-hidden");
			
			const limit = spawn.getBoundingClientRect().top - JS_SPAWN.body_rect_top;
			
			if( JS_SPAWN.check_below( limit ) ){
				
				spawn.classList.remove("spawn-hidden");
				
				spawn.classList.add("spawn");
				
			}else{
				
				JS_SPAWN.list.push( spawn );
				
				JS_SPAWN.lng++;
				
			}
			
		}
		
	}, 
	
	resize : () => {
		
		JS_SPAWN.spawn_limit = window.scrollY + window.innerHeight - JS_SPAWN.SPAWN_LIMIT_INCREMENT;
		
		JS_SPAWN.body_rect_top = document.body.getBoundingClientRect().top;
		
	}, 
	
	scroll : () => {
		
		for( let i = 0; i < JS_SPAWN.lng; i++ ){
			
			const limit = JS_SPAWN.list[i].getBoundingClientRect().top-JS_SPAWN.body_rect_top;
			
			if( JS_SPAWN.check_below( limit ) ){
				
				JS_SPAWN.list[i].classList.remove("spawn-hidden");
				
				JS_SPAWN.list[i].classList.add("spawn");
				
				JS_SPAWN.lng--;
				
				JS_SPAWN.list.splice(i,1);
				
			}
			
		}
		
	}, 
	
	check_below : ( limit ) => {
		
		return JS_SPAWN.spawn_limit > limit;
		
	}, 
	
	
};


const LOOP = {
	
	is_running : false, 
	
	last_date : Date.now(),
	
	start : () => {
		
		//~ console.log("LOOP start");
		
		if( ! LOOP.is_running ){
			
			requestAnimationFrame( LOOP.loop );
			
			LOOP.is_running = true;
			
		}
		
	}, 
	
	stop : () => {
		
		//~ console.log("LOOP stop");
		
		LOOP.is_running = false;
		
	}, 
	
	loop : () => {
		
		var dt = Date.now();
		
		var delta = dt - LOOP.last_date;
		
		LOOP.last_date = dt;
		
		POSTERS.loop(delta);
		
		//~ SLIDERS.loop(delta);
		
		//~ DEBUG.FPS.loop(delta);
		
		
		if( LOOP.is_running ){
			
			requestAnimationFrame( LOOP.loop );
			
		}
		
	}, 
	
	
	
	
};

const POSTERS = {
	
	update : false,
	
	LNG : 0,
	
	posters : [], 
	
	
	init : () => {
		
		const Ps = document.getElementsByClassName('js-poster');
		
		POSTERS.LNG = Ps.length;
		
		
		for ( let i = 0; i < POSTERS.LNG; i++ ) {
			
			const poster = Ps[i];
			
			const ob = {};
			
			ob.dom = poster;
			
			ob.img = poster.getElementsByTagName('img')[0]
			
			if ( poster.hasAttribute("data-scroll") ) {
				
				ob.img.style.height = ( 100 + poster.dataset.scroll / 2. ) + "%";
				
				//~ console.log(MAIN.E.POSTERS.OBS[i], MAIN.E.POSTERS.OBS[i].dataset.scroll, MAIN.E.POSTERS.IMGS[i].style.height);
				
			}
			
			POSTERS.posters.push(ob);
			
		}
		
		//~ console.log(POSTERS.posters);
		
		POSTERS.update = true;
		
	}, 
	
	
	scroll : () => {
		
		POSTERS.update = true;
		
	}, 
	
	
	loop : () => {
		
		if( POSTERS.update ){
			
			for ( let i = 0; i < POSTERS.LNG; i++ ) {
				
				const poster = POSTERS.posters[i];
				
				const rect = poster.dom.getBoundingClientRect();
				
				if ( rect.bottom >= 0 && rect.top <=  window.innerHeight ) {
					
					const inc = POSTERS.get_value( rect.top, rect.bottom );
					
					let trans;
					
					if (poster.dom.hasAttribute("data-scroll") ) {
						
						trans = `translate3d(-50%,${( -50 + ( -.5 + inc ) * poster.dom.dataset.scroll ).toFixed(2)}%,0)`;
						
					}else{
						
						trans = `translate3d(-50%, -50%, 0)`;
						
					}
					
					OUTILS.set_transform_prefixes( poster.img, trans );
					
					
					poster.dom.classList.add("active");
					
				}else{
					
					poster.dom.classList.remove("active");
					
				}
				
			}
			
			POSTERS.update = false;
			
		}
		
	}, 
	
	
	
	
	get_value : ( top, bottom ) => {
		
		const min = window.scrollY - window.innerHeight + top;
		const max = window.scrollY + bottom;
		
		const vs = window.scrollY - min;
		const vm = max - min;
		
		return vs / vm;
		
	}, 
	
};



const SIZE_SMALL = 0;
const SIZE_MEDIUM = 1;
const SIZE_LARGE = 2;
const SIZE_XLARGE = 4;


const MI_VIDEOS = {
	
	
	
	medias : [
		
		{
			srcs : {
				
				[SIZE_XLARGE] : "videos/Realsonix-Hear-for-Real-1920x1080-v30.mp4", 
				[SIZE_LARGE] : 	"videos/Realsonix-Hear-for-Real-1280x720-v30.mp4", 
				[SIZE_MEDIUM] : "videos/Realsonix-Hear-for-Real-1024x576-v25.mp4", 
				[SIZE_SMALL] : 	"videos/Realsonix-Hear-for-Real-640x360-v25.mp4", 
				
			}, 
		}, 
		
		{
			srcs : {
				
				[SIZE_XLARGE] : "videos/Realsonix-A-new-way-to-hear-and-feel-1920x1080-v30.mp4", 
				[SIZE_LARGE] : 	"videos/Realsonix-A-new-way-to-hear-and-feel-1280x720-v30.mp4", 
				[SIZE_MEDIUM] : "videos/Realsonix-A-new-way-to-hear-and-feel-1024x576-v25.mp4", 
				[SIZE_SMALL] : 	"videos/Realsonix-A-new-way-to-hear-and-feel-640x360-v25.mp4", 
				
			}, 
		}, 
		
		{
			srcs : {
				
				[SIZE_XLARGE] : "videos/Realsonix-Any-headphones-any-speakers-1920x1080-v30.mp4", 
				[SIZE_LARGE] : 	"videos/Realsonix-Any-headphones-any-speakers-1280x720-v30.mp4", 
				[SIZE_MEDIUM] : "videos/Realsonix-Any-headphones-any-speakers-1024x576-v25.mp4", 
				[SIZE_SMALL] : 	"videos/Realsonix-Any-headphones-any-speakers-640x360-v25.mp4", 
				
			}, 
		}, 
		
		{
			srcs : {
				
				[SIZE_XLARGE] : "videos/Realsonix-No-matter-the-location-or-device-1920x1080-v30.mp4", 
				[SIZE_LARGE] : 	"videos/Realsonix-No-matter-the-location-or-device-1280x720-v30.mp4", 
				[SIZE_MEDIUM] : "videos/Realsonix-No-matter-the-location-or-device-1024x576-v25.mp4", 
				[SIZE_SMALL] : 	"videos/Realsonix-No-matter-the-location-or-device-640x360-v25.mp4", 
				
			}, 
		}, 
		
	], 
	
	load_flag : 0, 
	
	play_flag : 0, 
	
	dom : {
		
		poster : undefined, 
		video : undefined, 
		
	}, 
	
	init : () => {
		
		MI_VIDEOS.dom.video = document.getElementById("Heading_index_video");
		
		MI_VIDEOS.dom.poster = document.querySelector("#Heading_index .poster");
		
		MI_VIDEOS.dom.video.loop = false;
		MI_VIDEOS.dom.video.autoplay = false;
		MI_VIDEOS.dom.video.playsinline = false;
		
		MI_VIDEOS.dom.poster.onanimationend = function() {
			this.classList.remove('flash');
		}
		
		const lis = document.querySelectorAll('#Heading_index li');
		
		const lng = MI_VIDEOS.medias.length;
		
		for( let i = 0; i < lng; i++ ){
			
			MI_VIDEOS.medias[i].li = lis[i];
			
		}
		
		MI_VIDEOS.load_video( MI_VIDEOS.medias[ MI_VIDEOS.load_flag ].srcs[ MI_VIDEOS.get_file_size() ] );
		
		MI_VIDEOS.dom.video.onended = MI_VIDEOS.on_ended;
		
		
		
	}, 
	
	load_video : ( url ) => {
		
		//~ console.log( "load_video", url );
		
		url = STATIC_URL + url;
		
		
		const req = new XMLHttpRequest();
		
		req.open('GET', url, true);
		
		req.responseType = 'blob';
		
		req.onload = function() {
			
			// Onload is triggered even on 404
			// so we need to check the status code
			
			if (this.status === 200) {
				
				req.onload = null;
				
				const vid = URL.createObjectURL( this.response );
				
				MI_VIDEOS.medias[ MI_VIDEOS.load_flag ].blob = vid;
				
				if( MI_VIDEOS.load_flag < MI_VIDEOS.medias.length-1 ){
					
					if( MI_VIDEOS.load_flag == 0 ){
						
						MI_VIDEOS.dom.poster.classList.add("flash");
						
						MI_VIDEOS.dom.video.play();
						
					}
					
					MI_VIDEOS.load_flag++;
					
					MI_VIDEOS.load_video( MI_VIDEOS.medias[ MI_VIDEOS.load_flag ].srcs[ MI_VIDEOS.get_file_size() ] );
					
					//~ console.log(MI_VIDEOS.get_file_size());
					//~ console.log(MI_VIDEOS.medias[ MI_VIDEOS.load_flag ].srcs[ MI_VIDEOS.get_file_size() ]);
					
				}
				
			}
		};
		
		req.send();
		
	}, 
	
	on_ended : ( event ) => {
		
		//~ // console.log("video end", event.target);
		
		let next_index = MI_VIDEOS.play_flag;
		
		next_index++;
		
		if( next_index > MI_VIDEOS.medias.length-1 ){
			
			next_index = 0;
			
		}
		
		if( MI_VIDEOS.medias[ next_index ].blob != undefined ){
			
			MI_VIDEOS.medias[ MI_VIDEOS.play_flag ].li.style.display = "none";
			
			MI_VIDEOS.play_flag = next_index;
			
			MI_VIDEOS.dom.poster.classList.add("flash");
			
			MI_VIDEOS.dom.video.src = MI_VIDEOS.medias[ MI_VIDEOS.play_flag ].blob;
			
			MI_VIDEOS.medias[ MI_VIDEOS.play_flag ].li.style.display = "inline-block";
			// MI_VIDEOS.medias[ MI_VIDEOS.play_flag ].li.style.display = "block";
			
		}
		
		MI_VIDEOS.dom.video.play();
		
	}, 
	
	get_file_size : () => {
		
		//~ console.log(window.innerWidth);
		
		
		const wiw = window.innerWidth;
		
		if( wiw >= 2700 ){
			
			return SIZE_XLARGE;
			
		}else if( wiw >= 1800 ){
			
			return SIZE_LARGE;
			
		}else if( wiw >= 1200 ){
			
			return SIZE_MEDIUM;
			
		}else{
			
			return SIZE_SMALL;
			
		}
		
		
	}, 
	
};



const OUTILS = {
	
	
	set_transform_prefixes : function ( element, transform ){
		
		element.style.webkitTransform = 
		element.style.mozTransform = 
		element.style.msTransform = 
		element.style.oTransform = 
		element.style.transform = transform;
		
	}, 
	
	
};


export{
	
	boot, 
	
};


