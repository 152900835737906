

import "./assets/styles/imports.css";
import "./assets/styles/normalize.css";
import "./assets/styles/config.css";

//~ import "./assets/styles/main.css";

//~ import "./assets/styles/texts.css";
//~ import "./assets/styles/buttons.css";
//~ import "./assets/styles/gameui.css";
//~ import "./assets/styles/forms.css";

//~ import "./assets/styles/debug.css";

import "./assets/styles/main.css";
import "./assets/styles/header.css";
import "./assets/styles/footer.css";


//~ import "./assets/styles/palette.css";




import {boot} from './assets/js/main.js';

boot();






